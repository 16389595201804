import CeoImg from '../../asset/img/대표사진.png';
import bookImg from '../../asset/img/책표지.png';
import bookPdf from '../../asset/files/코인 채굴로 전자책0704.pdf';
import { ceoData, ceoCertData } from '../../data';
import DivideLine from '../common/divideLine';
import { IdentificationIcon, ShieldCheckIcon, BookOpenIcon, LinkIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';

function HistoryRow({ col1, col2 }) {
  return (
    <div className="flex flex-row my-1 items-center">
      <div className='mr-2'><span className='date text-lg'>{col1}</span></div>
      <div>{col2}</div>
    </div>
  );
}

function CertRow({ col }) {
  return (
    <div className="flex flex-row my-1 items-center">
      <div className='mr-2'>
        <span className='date text-lg flex flex-row'>
          <ShieldCheckIcon className='w-6 h-6'></ShieldCheckIcon>
        </span>
      </div>
      <div>{col}</div>
    </div>
  )
}

function BookRow() {
  return (
    <>
      <div className="flex flex-row my-2 items-center">
        <div className='mr-2'>
          <span className='date text-lg flex flex-row'>
            <BookOpenIcon className='w-6 h-6'></BookOpenIcon>
          </span>
        </div>
        <div>
          <a className='flex flex-row items-center' href="https://kmong.com/gig/583447">
            <span className='font-semibold mr-1'>"00년생이 코인채굴로 6개월간 4억번 비결"</span>집필
          </a>
        </div>
      </div>
      <div className="flex flex-row my-2 items-center">
        <div className='date flex flex-row mr-3 text-sm items-center'>
          <div className='mr-2'>
            <span className='flex flex-row'>
              <LinkIcon className='w-4 h-4'></LinkIcon>
            </span>
          </div>
          <div>
            <a className='flex flex-row items-center' href="https://kmong.com/gig/583447">
              <span className='font-bold text-opacity-70 hover:text-blue-800'>크몽 바로가기</span>
            </a>
          </div>
        </div>
        <div className='date flex flex-row text-sm items-center'>
          <div className='mr-2'>
            <span className='flex flex-row'>
              <ArrowDownTrayIcon className='w-4 h-4'></ArrowDownTrayIcon>
            </span>
          </div>
          <div>
            <a href={bookPdf} className='flex flex-row items-center' download="00년생이 코인채굴로 6개월간 4억번 비결.pdf">
              <span className='font-bold text-opacity-70 hover:text-blue-800'>책 미리보기 (PDF 다운로드)   </span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default function CEOHistory() {
  return (
    <div className="relative isolate py-12">
      <div className="flex flex-row items-center justify-center mb-12">
        <IdentificationIcon className="h-12 w-12 mr-3" />
        <span className="font-semibold opacity-80 text-4xl">
          대표 이력
        </span>
      </div>
      <div className="flex justify-center">
        <div className='md:w-4/5 flex flex-col md:flex-row items-start justify-center'>
          <div className="mx-auto md:w-2/5 flex flex-col items-center mb-6 md:mb-0">
            <img className="h-72 w-auto" src={CeoImg} alt="대표 이미지" />
            <div className="py-3 text-xl text-center font-bold">(대표) 이의영</div>
          </div>
          <div className="mx-auto md:w-3/5 px-4 md:px-0">
            <div className="flex justify-center">
              <div className="flex flex-col">
                <div className="mb-3">
                  <h3 className="text-2xl font-semibold">히스토리</h3>
                </div>
                {ceoData.map((element, index) => (
                  <HistoryRow key={index} {...element} />
                ))}
                <DivideLine></DivideLine>
                <div className="mb-3">
                  <h3 className="text-2xl font-semibold">자격</h3>
                </div>
                {ceoCertData.map((element, index) => (
                  <CertRow key={index} col={element} />
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>
      <DivideLine></DivideLine>
      <div className="flex justify-center">
        <div className='md:w-4/5 flex flex-col md:flex-row items-start justify-center'>
          <div className="mx-auto md:w-2/5 flex flex-col items-center mb-6 md:mb-0">
            <img className="h-72 w-auto" src={bookImg} alt="책 이미지" />
          </div>
          <div className="md:w-3/5 px-4 md:px-0">
            <div className="flex ps-0 lg:ps-16 xl:ps-24">
              <div className="flex flex-col">
                <div className="mb-3">
                  <h3 className="text-2xl font-semibold">저서</h3>
                </div>
                <BookRow />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
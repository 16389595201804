import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';

export default function YouTubeVideo({ src }) {
  const [videoSize, setVideoSize] = useState({
    height: '390',
    width: '640',
  });

  useEffect(() => {
    const screenWidth = window.innerWidth;
    let newWidth = screenWidth * 0.9; // 화면의 80%를 차지하도록 설정
    let newHeight = (newWidth * 3) / 4; // 16:9 비율 유지

    if (newWidth > 640) {
      newWidth = 640;
      newHeight = 390;
    }

    setVideoSize({
      width: newWidth.toFixed(0),
      height: newHeight.toFixed(0),
    });
  }, []); // 빈 배열을 넣어 useEffect가 최초 로딩 시에만 실행되도록 설정

  const opts = {
    height: videoSize.height,
    width: videoSize.width,
    playerVars: {
      autoplay: 0, // 자동 재생
      controls: 1, // 컨트롤러 활성화
    },
  };

  return (
    <div className="mx-auto lg:mx-0 lg:mr-12 mb-12 lg:mb-0">
      <YouTube videoId={src} opts={opts} />
    </div>
  );
}

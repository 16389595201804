import BasePage from "../../common/base"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import DivideLine from "../../common/divideLine";
import MiningLogo from "../../../asset/components/MiningLogo";
import { 
    WalletIcon, 
    PaperAirplaneIcon, 
    ArrowPathRoundedSquareIcon, 
    CurrencyDollarIcon,
    ArrowDownTrayIcon
} from "@heroicons/react/20/solid";

const QnAData = [
    {
        'question': "코인 채굴이란?",
        'answer': "블록체인 생태계에서 컴퓨팅 파워를 활용해 블록을 생성하는 행위입니다. 블록을 생성하면 그 보상으로 코인을 받게 됩니다. 금광에서 금을 캐는 행위와 비슷하다고 하여 이를 채굴이라고 부릅니다."
    },
    {
        'question': "코인을 주는 주체?",
        'answer': "코인을 주는 주체는 없습니다. 코인 채굴자가 주체이며 주주입니다. 블록체인의 가장 큰 특징은 탈중앙화입니다. 코인이 처음 개발되고나면, 비트코인의 총 수량이 2100만개인 것 처럼, 총 수량이 정해집니다. 이 안에서 본인이 원하는만큼 자유로이 컴퓨팅 파워를 늘려 보상을 얻는 구조입니다."
    },
    {
        'question': "코인이 진짜 돈이 되는가?",
        'answer': "24년 말까지 전세계 암호화폐 시장 규모가 5조 달러 (7000조)에 육박할것이라 예측하고 있습니다. 비트코인을 법정화폐로 체택한 국가도 존재할 뿐 아니라, 2028년 1월부터 암호화폐로 벌어들인 소득에 대해 정부에서 과세하도록 하는 법안이 개정됩니다. 국가는 실체가 없거나 법의 테두리 안에 있지 않은 것에 세금을 부여하지 않습니다. 코인이 돈이 된다는 건 부정할 수 없는 자명한 사실입니다."
    }
]

export default function ProfitableCoinPage() {
    return (
        <BasePage>
            <section className="lg:my-36 my-24 flex justify-center">
                <div className="flex flex-col justify-center flex-1 items-center lg:gap-32 gap-24">
                    {
                        QnAData.map((element, index) => (
                            <div key={index} className="flex flex-col gap-12 w-11/12 md:w-4/5 lg:w-2/3 px-6 py-12 border border-l-slate-300 shadow-lg rounded-lg shadow-slate-300">
                                {
                                    <div className="mx-auto max-w-2xl sm:text-center">
                                        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">{element.question}</h2>
                                        <p className="mt-12 md:text-lg leading-8 text-gray-600 tracking-tight">{element.answer}</p>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </section>
            <DivideLine />
            <ProcessFlow />
        </BasePage>
    )
}

const steps = [
    {
        "step": "상장된 코인을 컴퓨팅 파워로 채굴",
        "icon": MiningLogo
    },
    {
        "step": "채굴한 코인을 지갑에 적립",
        "icon": WalletIcon
    },
    {
        "step": "채굴한 코인을 거래소 전송",
        "icon": PaperAirplaneIcon
    },
    {
        "step": "거래소에서 코인 매도",
        "icon": ArrowPathRoundedSquareIcon
    },
    {
        "step": "매도한 코인을 USDT로 수령",
        "icon": CurrencyDollarIcon
    },
    {
        "step": "USDT를 국내 거래소에서 원화로 출금",
        "icon": ArrowDownTrayIcon
    },
];


function StepItem({ step, isLast, Icon }) {
    return (
        <div className="flex items-center flex-col">
            <div className=" bg-slate-900 bg-opacity-85 px-8 py-6 rounded-lg text-center stepper shadow-sm shadow-zinc-700 flex flex-row items-center justify-center">
                <Icon className="h-6 w-auto mr-3 fill-white"/>
                <span className="font-semibold md:text-lg lg:text-xl tracking-tight text-gray-100">{step}</span>
            </div>
            {!isLast && (
                <div className="text-gray-500 mt-4">
                    <svg
                        className="h-6 w-6 transform rotate-90"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                    </svg>
                </div>
            )}
        </div>
    );
}

function ProcessFlow() {
    return (
        <div className="flex flex-col items-center justify-center space-y-8 p-4 mt-24">
            {/* 제목 */}
            <div className="text-2xl md:text-4xl font-bold mb-6">코인이 현금화 되는 과정</div>

            {/* 과정 단계 */}
            <div className="flex flex-col items-center space-y-4">
                {steps.map((step, index) => (   
                    <StepItem
                        key={index}
                        step={step.step}
                        isLast={index === steps.length - 1}
                        Icon={step.icon}
                    />
                ))}
            </div>
        </div>
    );
}
export const corporationData = [
    { col1: "2023.8", col2: "<블록체인혁명_돈 탭스콧> 탐독" },
    { col1: "2023.9", col2: "첫 채굴기 설치" },
    { col1: "2023.10", col2: "상가 사무실 소형 채굴장 설비" },
    { col1: "2024.8~", col2: "500대 규모 채굴장 운영" },
]

export const ceoData = [
    { col1: "2011", col2: "MBC 영어골든벨 1위 & 2019 대한민국 학생 영어말하기대회 전국 2위" },
    { col1: "2020", col2: "TORFL 2 (노어 석사졸업요건)" },
    { col1: "2022", col2: "대한민국 학생 영어말하기대회 왕중왕전 수상" },
    { col1: "2022", col2: "공군학사장교합격 & TOEIC 990" },
    { col1: "2022", col2: "주-한 러시아 대사관 및 한국외대 주최 전국 러시아어 토론대회 수상" },
    { col1: "2024", col2: "업비트 투자대회" },
]

export const ceoCertData = [
     "CBM (Certified BlockChain Manager) 국제블록체인관리사 자격증",
    "Unix & Linux vim shell expert",
    "500대 규모 블록체인 채굴장 운영 中",
]
import DivideLine from './common/divideLine';
import CEOHistory from './HomePage/CeoHistory';
import CorpHistory from './HomePage/CorpHistory';
import CompanyMilestone from './HomePage/CorpIntroduce';
import BasePage from './common/base';

export default function Home() {
  return (
    <BasePage>
      <CompanyMilestone />
      <DivideLine />
      <CorpHistory />
      <DivideLine />
      <CEOHistory />
    </BasePage>
  );
}

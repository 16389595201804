import DivideLine from './common/divideLine';
import PackageSecion from './PackagePage/Package.jsx';
import BasePage from './common/base.jsx';

import goldImg from '../asset/img/금.jpg';
import bitImg from '../asset/img/비트코인.jpg';
import rippleImg from '../asset/img/리플.jpg';

const packageContent = [
    {
        "title": "수익형 코인 채굴",
        "content": "칸 랩이 선별한 코인 포트폴리오에 따라 상장된 채굴코인을 채굴하여 즉시 매도 합니다. 환급성이 뛰어난 수익형 채굴입니다.",
        "link": "/package/profitable-coin",
        "img": goldImg
    },
    {
        "title": "비트코인 스테이킹",
        "content": "수익형 채굴 + 비트코인 매수형 패키지 입니다.",
        "link": "/package/bitcoin-staking",
        "img": bitImg
    },
    {
        "title": "리플 스테이킹",
        "content": "수익형 채굴 + 리플 매수형 패키지 입니다.",
        "link": "/package/ripple-staking",
        "img": rippleImg
    }
]


export default function Package() {
    return (
        <BasePage>
            {
                packageContent.map((element, index) => (
                    <div key={index}>
                        <PackageSecion {...element} />
                        {index !== (packageContent.length - 1) && <DivideLine />}
                    </div>
                ))
            }
        </BasePage>
    );
}

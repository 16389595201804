import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from './components/Home.jsx';
import Place from './components/Place.jsx';
import Lecture from './components/Lecture.jsx';
import Package from './components/Package.jsx';
import ProfitableCoinPage from './components/PackagePage/DetailPage/ProfitablePage.jsx';
import BitcoinStakingPage from './components/PackagePage/DetailPage/BitStakingPage.jsx';
import RippleStakingPage from './components/PackagePage/DetailPage/RippleStakingPage.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/about" />} />
        <Route path="/about" element={<Home />} />
        <Route path="/place" element={<Place />} />
        <Route path="/lecture" element={<Lecture />} />
        <Route path="/package" element={<Package />} />
        <Route path="/package/profitable-coin" element={<ProfitableCoinPage />} />
        <Route path="/package/bitcoin-staking" element={<BitcoinStakingPage />} />
        <Route path="/package/ripple-staking" element={<RippleStakingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
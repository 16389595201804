import DivideLine from './common/divideLine';
import MainPhoto from './common/mainPhoto';
import Menu from './common/menu';


export default function Home() {
    return (
        <div className="bg-white">
            <Menu />
            <MainPhoto />
        </div>
    );
}

import MainPhoto from './mainPhoto';
import Menu from './menu';


export default function BasePage({children}) {
    return (
        <div className="bg-white">
            <Menu />
            <MainPhoto />
            <div className='container mx-auto'>
                {children}
            </div>
        </div>
    );
}

import { ArrowTurnDownRightIcon } from "@heroicons/react/24/outline"

export default function PackageSecion({ title, content, link, img }) {
    return (
        <section className="my-24 flex justify-center">
            <div className="flex flex-col justify-center flex-1 items-center lg:flex-row lg:justify-start lg:items-start">
                <div className="lg:w-1/3 lg:mb-0 mb-6">
                    <img className='lg:h-64 lg:w-96 w-4/5 h-auto mx-auto' src={img} alt={title + '이미지'}></img>
                </div>
                <div className="flex flex-col lg:ml-6 gap-4 lg:w-2/3 w-4/5 lg:mx-0">
                    <div>
                        <a href={link} className="flex flex-row gap-3 items-center">
                            <h3 className="text-xl font-semibold text-blue-500 hover:text-blue-700 underline">
                                {title}
                            </h3>
                            <ArrowTurnDownRightIcon className="h-4 w-4 inline-block stroke-blue-700" />
                        </a>
                    </div>
                    <div className="leading-relaxed">
                        {content}
                    </div>
                </div>
            </div>
        </section>
    )
}